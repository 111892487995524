import mediumZoom from 'medium-zoom'

document.querySelectorAll('[udesly-zoom]').forEach(el=>{

    const margin = Number(el.getAttribute('zoom-margin')) || 0

    const background = el.getAttribute('zoom-background') || 'rgba(0,0,0,0)'

    const scrollOffset = Number(el.getAttribute('zoom-scroll-offset')) || 40

    mediumZoom(el as HTMLElement, {
        margin: margin,
        background: background,
        scrollOffset: scrollOffset
    });

   
})